<template>
  <div id="app">
    <main class="p-10">
      <h1 class="text-center font-bold text-4xl mb-4">SPA demo store</h1>
      <router-view />
    </main>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>
